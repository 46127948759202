import { FC } from 'react'
import { ZodType, ZodTypeDef } from 'zod'
import { IFormAction } from '~/core/@types/global'
import { ApplicationFormType } from '~/lib/features/apply/jobId/types'
import { ApplicationField } from '~/lib/features/jobs/types/application-fields'
import useTermAndConditionHook from '~/lib/features/settings/careers/hooks/use-term-and-condition-hook'
import ApplicationCustomFieldsForm from './ApplicationCustomFieldsForm'
import DefaultApplicationForm from './DefaultApplicationForm'

const ApplicationForm: FC<{
  isSubmitting: boolean
  job: {
    title: string
  }
  onFinishCallback?: (
    data: ApplicationFormType,
    formAction: IFormAction
  ) => Promise<void>
  defaultValue?: ApplicationFormType
  disableFields?: Array<string>
  schema?: ZodType<any, ZodTypeDef, any>
  isPublicPage?: boolean
  applicationFields?: ApplicationField[]
  onCancelModal?: () => void
}> = ({
  isSubmitting,
  job,
  onFinishCallback,
  defaultValue,
  disableFields = [],
  schema,
  isPublicPage,
  applicationFields = [],
  onCancelModal
}) => {
  const { termAndConditions } = useTermAndConditionHook({
    isPublicPage,
    // @ts-expect-error
    tenantSlug: String(job?.tenant?.slug)
  })

  return !termAndConditions ? null : applicationFields?.length > 0 ? (
    <ApplicationCustomFieldsForm
      isSubmitting={isSubmitting}
      applicationFields={applicationFields}
      onClickCancelModal={onCancelModal}
      defaultValue={defaultValue}
      termAndConditions={termAndConditions}
      disableFields={disableFields}
      onSubmitForm={onFinishCallback}
    />
  ) : (
    <DefaultApplicationForm
      isSubmitting={isSubmitting}
      defaultValue={defaultValue}
      termAndConditions={termAndConditions}
      schema={schema}
      onFinishCallback={onFinishCallback}
      disableFields={disableFields}
      onClickCancelModal={onCancelModal}
    />
  )
}

export default ApplicationForm
