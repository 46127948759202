import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ZodType, ZodTypeDef } from 'zod'
import { IFormAction } from '~/core/@types/global'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { Dialog } from '~/core/ui/Dialog'
import { ApplicationFormType, JobType } from '~/lib/features/apply/jobId/types'
import QueryInternalApplicationCustomFields from '~/lib/features/candidates/graphql/query-internal-application-custom-fields'
import QueryPublicApplicationCustomFields from '~/lib/features/jobs/graphql/query-public-application-custom-fields'
import { ApplicationField } from '~/lib/features/jobs/types/application-fields'
import { JobDetailType } from '~/lib/features/referrals/types'
import ApplicationForm from './ApplicationForm'

const ApplicationFormModal: FC<{
  view?: 'default' | 'custom'
  open: boolean
  setOpen: (open: boolean) => void
  job: JobType | JobDetailType
  tenantSlug?: string
  isSubmitting?: boolean
  defaultValue?: ApplicationFormType
  onSubmit?: (
    data: ApplicationFormType,
    formAction: IFormAction
  ) => Promise<void>
  disableFields?: Array<string>
  schema: ZodType<any, ZodTypeDef, any>
  isPublicPage?: boolean
}> = ({
  view = 'default',
  tenantSlug,
  open,
  setOpen,
  job,
  defaultValue,
  isSubmitting,
  onSubmit,
  disableFields,
  schema,
  isPublicPage = false
}) => {
  const { t } = useTranslation()

  const {
    data: applicationFieldsResponse,
    isLoading: isFetchingApplicationsFields
  } = useQueryGraphQL(
    isPublicPage
      ? {
          query: QueryPublicApplicationCustomFields,
          variables: {
            jobId: Number(job.id),
            tenantSlug: tenantSlug
          },
          shouldPause: !job.id || !tenantSlug || view !== 'custom'
        }
      : {
          query: QueryInternalApplicationCustomFields,
          variables: {
            jobId: Number(job.id)
          },
          shouldPause: !job.id || view !== 'custom'
        }
  )

  const applicationFields: ApplicationField[] =
    (isPublicPage
      ? applicationFieldsResponse?.publicJobApplicationFieldsList
      : applicationFieldsResponse?.internalJobApplicationFieldsList) || []

  return (
    <Dialog
      open={open}
      size="md"
      onOpenChange={setOpen}
      isDivider={false}
      isPreventAutoFocusDialog={true}
      label={`${t('careers:applied:yourApplication')}`}
      description={job.title}
      headingClassName="pb-4 tablet:pb-5">
      <ApplicationForm
        isSubmitting={!!isSubmitting}
        job={job}
        onFinishCallback={onSubmit}
        onCancelModal={() => setOpen(false)}
        defaultValue={defaultValue}
        disableFields={disableFields}
        schema={schema}
        isPublicPage={isPublicPage}
        applicationFields={applicationFields}
      />
    </Dialog>
  )
}

export default ApplicationFormModal
