import { TFunction } from 'i18next'
import { z } from 'zod'
import { regexEmailValidation } from '~/core/utilities/common'
import { ApplicationField } from '~/lib/features/jobs/types/application-fields'
import { renderKeyCustomFieldForm } from '~/lib/features/settings/profile-fields/mapping/custom-field-mapping'
import { schemaCustomField } from '~/lib/features/settings/profile-fields/schema/custom-field-schema'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  convertFileSizeToBytes
} from '~/lib/schema'

const SYSTEM_FIELDS_SCHEMA = (
  t: TFunction,
  isRequired = false
): { [key: string]: any } => ({
  fullName: z
    .string()
    .optional()
    .refine(
      (data) => {
        return isRequired ? (data || '').length > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    )
    .refine(
      (data) => {
        const validateMaxLength = (data || '').length <= 100
        return validateMaxLength
      },
      {
        message: `${t('form:field_max_number_required', { number: 100 })}`
      }
    ),
  email: z
    .string()
    .trim()
    .optional()
    .refine((value) => (isRequired ? (value || '').trim() !== '' : true), {
      message: `${t('form:requiredField')}`
    })
    .refine((value) => regexEmailValidation.test(value || ''), {
      message: `${t('form:invalid_email')}`
    }),
  phoneNumber: z
    .string()
    .trim()
    .optional()
    .refine(
      (data) => {
        const validateMaxLength = (data || '').length <= 15
        return validateMaxLength
      },
      {
        message: `${t('form:field_max_number_required', { number: 15 })}`
      }
    )
    .refine(
      (data) => {
        return isRequired ? (data || '').length > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  headline: z
    .string()
    .max(200, {
      message: `${t('form:field_max_number_required', { number: 200 })}`
    })
    .optional()
    .refine(
      (data) => {
        return isRequired ? (data || '')?.length > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  profileCvs: z
    .any()
    .array()
    .default([])
    .refine(
      (files) => {
        return (
          !files?.[0] ||
          typeof files?.[0] === 'string' ||
          files?.[0]?.size <= convertFileSizeToBytes({ size: MAX_FILE_SIZE })
        )
      },
      `${t('form:invalid_upload_file', {
        maximumSizeFile: MAX_FILE_SIZE
      })}`
    )
    .refine(
      (files) =>
        !files?.[0] ||
        typeof files?.[0] === 'string' ||
        ACCEPTED_FILE_TYPES.includes(files?.[0]?.type),
      `${t('form:invalid_upload_file', {
        maximumSizeFile: MAX_FILE_SIZE
      })}`
    )
    .refine(
      (data) => {
        return isRequired ? data.length > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  coverLetter: z
    .nullable(
      z.string().max(500, {
        message: `${t('form:field_max_number_required', {
          number: 500
        })}`
      })
    )
    .optional()
    .refine(
      (data) => {
        return isRequired ? (data || '').length > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  profileLevel: z
    .object({
      value: z.string()
    })
    .optional()
    .refine(
      (data) => {
        return isRequired ? !!data?.value : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  birthday: z
    .object({
      date: z.number().nullish().optional(),
      month: z.number().nullish().optional(),
      year: z.number().nullish().optional()
    })
    .default({})
    .refine(
      (val) => {
        if (isRequired && !val?.date) {
          return false
        }
        return true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  languages: z
    .array(
      z.object({
        language: z.object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        }),
        proficiency: z
          .object({
            value: z.string(),
            supportingObj: z.object({
              name: z.string()
            })
          })
          .nullish()
          .optional()
      })
    )
    .default([])
    .refine(
      (data) => {
        return isRequired ? (data || []).length > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  nationality: z
    .string()
    .default('')
    .refine(
      (data) => {
        return isRequired ? (data || '').length > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  willingToRelocate: z.boolean().optional(),
  noticeToPeriodDays: z
    .nullable(
      z.object({
        value: z.string().optional(),
        supportingObj: z
          .object({
            name: z.string()
          })
          .optional()
      })
    )
    .default({})
    .refine(
      (data) => {
        return isRequired ? !!data?.value : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  currentSalary: z
    .object({
      typeOfSalary: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .optional(),
      salary: z
        .number({
          invalid_type_error: `${t(
            'form:salary_should_only_contain_numerical_values'
          )}`
        })
        .int({
          message: `${t('form:decimals_are_not_allowed')}`
        })
        .nonnegative({
          message: `${t('form:salary_can_not_be_zero_or_negative')}`
        })
        .default(0),
      currency: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .optional()
    })
    .default({})
    .refine(
      (data) => {
        return isRequired ? data.salary > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  expectedSalary: z
    .object({
      typeOfSalary: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .optional(),
      salary: z
        .number({
          invalid_type_error: `${t(
            'form:salary_should_only_contain_numerical_values'
          )}`
        })
        .int({
          message: `${t('form:decimals_are_not_allowed')}`
        })
        .nonnegative({
          message: `${t('form:salary_can_not_be_zero_or_negative')}`
        })
        .default(0),
      currency: z
        .object({
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .optional()
    })
    .default({})
    .refine(
      (data) => {
        return isRequired ? data.salary > 0 : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    ),
  totalYearsOfExp: z
    .object({
      value: z.string(),
      supportingObj: z.object({
        name: z.string()
      })
    })
    .optional()
    .refine(
      (data) => {
        return isRequired ? !!data?.value : true
      },
      {
        message: `${t('form:requiredField')}`
      }
    )
})

const schemaApplicationFormCustom = ({
  t,
  applicationFields
}: {
  t: TFunction
  applicationFields?: ApplicationField[]
}) => {
  const { systemFields, additionalFields } = (applicationFields || []).reduce(
    (result, field) => {
      return {
        systemFields: [
          ...result.systemFields,
          ...(['default', 'system'].includes(field.field_level) ? [field] : [])
        ],
        additionalFields: [
          ...result.additionalFields,
          ...(['default', 'system'].includes(field.field_level) ? [] : [field])
        ]
      }
    },
    {
      systemFields: [] as ApplicationField[],
      additionalFields: [] as ApplicationField[]
    }
  )
  return z
    .object({
      ...systemFields.reduce(
        (result, systemField) => ({
          ...result,
          [systemField.field_name]: SYSTEM_FIELDS_SCHEMA(
            t,
            systemField.required
          )?.[systemField.field_name]
        }),
        {}
      ),
      termsAndConditions: z.boolean().optional()
    })
    .and(
      schemaCustomField(
        t,
        additionalFields
          .filter((field) => !!field.required)
          .map((field) => Number(field.custom_field_id))
      )
    )
}

export default schemaApplicationFormCustom
