import { FC } from 'react'
import { Control, FieldErrors, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import { MAX_CHARACTERS_INTRODUCTION } from '~/core/constants/enum'
import { UploadFileDragAndDrop } from '~/core/ui/FileDragAndDrop'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import { InputRightElement } from '~/core/ui/InputElement'
import { InputGroup } from '~/core/ui/InputGroup'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { PhoneInput } from '~/core/ui/PhoneInput'
import { ISelectOption } from '~/core/ui/Select'
import { SingleDateWithYearOnlyPicker } from '~/core/ui/SingleDateWithYearOnlyPicker'
import { Textarea } from '~/core/ui/TextArea'
import { Toggle } from '~/core/ui/Toggle'
import { useDetectCountryCodeFromTimeZone } from '~/lib/countries-mapping/hooks/use-detect-country-code-from-time-zone'
import {
  ListSuggestNoticeOfPeriod,
  totalYoeOptions
} from '~/lib/features/candidates/utilities/enum'
import useUploadS3AWS from '~/lib/hooks/use-upload-s3-aws'
import LanguageControl from './LanguageControl'
import SalaryControl from './SalaryControl'

export type SystemFieldType = {
  systemFieldName?:
    | 'fullName'
    | 'email'
    | 'phoneNumber'
    | 'headline'
    | 'profileCvs'
    | 'coverLetter'
    | 'profileLevel'
    | 'birthday'
    | 'languages'
    | 'nationality'
    | 'willingToRelocate'
    | 'noticeToPeriodDays'
    | 'currentSalary'
    | 'expectedSalary'
    | 'totalYearsOfExp'
  name: string
  label?: string
  labelRequired?: boolean
  value?: any
  extra?: {
    attachments?: {
      id: string
      blobs: {
        filename: string
        content_type: string
        size: number
      }
      file: string
    }[]
  }

  onChange: (value: any) => void
  error?: FieldErrors<any>
  isDisabled?: boolean
  control?: Control<any>
  triggerValidate?: UseFormTrigger<any>
}

const SystemField: FC<SystemFieldType> = ({
  systemFieldName = 'fullName',
  name,
  label,
  labelRequired,
  isDisabled,
  value,
  extra,
  onChange,
  error,
  control,
  triggerValidate
}) => {
  const { t, i18n } = useTranslation()
  const { countryCode } = useDetectCountryCodeFromTimeZone()
  const { files, setFiles } = useUploadS3AWS(
    extra && systemFieldName === 'profileCvs'
      ? {
          defaultFiles: (extra?.attachments || []).map((item) => ({
            id: item.id,
            url: '',
            name: item.blobs.filename,
            status: 'upload',
            statusDescription: '',
            file: undefined
          }))
        }
      : {}
  )
  const profileLevel = useEnumsData({
    enumType: 'ProfileProfileLevel',
    locale: i18n.language
  })

  const defaultFormControlProps = {
    destructive: error && !!error?.[name],
    destructiveText: error && (error?.[name]?.message as string)
  }
  return (
    {
      fullName: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <Input
            autoFocus
            placeholder={`${t('careers:applied:fullNamePlaceholder')}`}
            size="sm"
            onChange={onChange}
            value={value}
            destructive={defaultFormControlProps.destructive}
            className="brand-color-input-focus"
            isDisabled={isDisabled}
          />
        </FormControlItem>
      ),
      email: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <Input
            placeholder={`${t('careers:applied:emailPlaceholder')}`}
            size="sm"
            onChange={onChange}
            // isDisabled={disableFields.includes('email')}
            value={value}
            destructive={defaultFormControlProps.destructive}
            className="brand-color-input-focus"
            isDisabled={isDisabled}
          />
        </FormControlItem>
      ),
      phoneNumber: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <PhoneInput
            size="sm"
            country={countryCode}
            onChange={onChange}
            value={value}
            placeholder={`${t('form:phone_number_field_placeholder')}`}
            inputClassName="brand-color-input-focus"
            isDisabled={isDisabled}
          />
        </FormControlItem>
      ),
      headline: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <Input
            placeholder={`${t('careers:applied:headlinePlaceholder')}`}
            size="sm"
            onChange={onChange}
            value={value}
            destructive={defaultFormControlProps.destructive}
            className="brand-color-input-focus"
            isDisabled={isDisabled}
          />
        </FormControlItem>
      ),
      profileCvs: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <UploadFileDragAndDrop
            className="w-full"
            classNameWrapper="brand-color-input-focus"
            textButtonClassName="brand-color-text"
            isDisabled={isDisabled}
            configText={{
              clickToUpload: `${t('label:dragAndDrop:clickToUpload')}`,
              orDragAndDrop: `${t('label:dragAndDrop:orDragAndDrop')}`,
              delete: `${t('tooltip:delete')}`,
              tryAgain: `${t('tooltip:tryAgain')}`,
              uploadANewFile: `${t('tooltip:uploadANewFile')}`
            }}
            dragNDropHelperText={`${t('careers:applied:dragNDropTypeFiles')}`}
            maximumFiles={1}
            accept={{
              'application/pdf': ['.pdf'],
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx']
            }}
            files={files as File[]}
            onChange={(files) => {
              onChange(files)
              setFiles(files || [])
            }}
            hideDeleteFileButton={true}
          />
        </FormControlItem>
      ),
      coverLetter: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <Textarea
            size="sm"
            onChange={onChange}
            value={value}
            limit={MAX_CHARACTERS_INTRODUCTION}
            placeholder={`${t('careers:applied:coverLetterPlaceholder')}`}
            destructive={defaultFormControlProps.destructive}
            wrapperFocusClassName="brand-color-ring brand-color-border"
            isDisabled={isDisabled}
          />
        </FormControlItem>
      ),
      profileLevel: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <NativeSelect
            size="sm"
            isClearable
            isSearchable
            isDisabled={isDisabled}
            onChange={onChange}
            value={(profileLevel || []).find(
              (item: ISelectOption) => String(item.value) === String(value)
            )}
            options={profileLevel}
            placeholder={`${t('careers:applied:selectExperienceLevel')}`}
            classNameOverride={{
              loadingMessage: `${t('label:loading')}`,
              noOptionsMessage: `${t('label:noOptions')}`
            }}
            destructive={defaultFormControlProps.destructive}
          />
        </FormControlItem>
      ),
      birthday: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <SingleDateWithYearOnlyPicker
            type="default"
            // placeholder={`${t('careers:applied:formatSupportBirthday')}`}
            config={{
              onChange: (value) => onChange(value),
              value: value,
              showClearIndicator: true
            }}
            defaultTab={
              !value?.month && !value?.date && !!value?.year ? 'year' : 'date'
            }
          />
        </FormControlItem>
      ),
      languages: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          {control && triggerValidate && (
            <LanguageControl
              section={{}}
              value={value}
              onChange={onChange}
              control={control}
              triggerValidate={triggerValidate}
            />
          )}
        </FormControlItem>
      ),
      nationality: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <Input
            placeholder={t('careers:applied:inputCountry') as string}
            size="sm"
            onChange={onChange}
            value={value}
            destructive={defaultFormControlProps.destructive}
            isDisabled={isDisabled}
          />
        </FormControlItem>
      ),
      willingToRelocate: (
        <FormControlItem
          mode="horizontal"
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <div className="py-1">
            <Toggle
              isChecked={value}
              name="view-switch"
              onCheckedChange={onChange}
              size={'sm'}
              // text={label}
              toggle="trailing"
              isDisabled={isDisabled}
            />
          </div>
        </FormControlItem>
      ),
      noticeToPeriodDays: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <NativeSelect
            size="sm"
            isClearable
            isSearchable
            onChange={onChange}
            isDisabled={isDisabled}
            value={(ListSuggestNoticeOfPeriod || []).find(
              (item: ISelectOption) => String(item.value) === String(value)
            )}
            options={ListSuggestNoticeOfPeriod.map((item) => ({
              value: `${item.value} ${t('label:days')}`,
              supportingObj: {
                name: `${item.value} ${t('label:days')}`
              }
            }))}
            placeholder={`${t('careers:applied:selectExperienceLevel')}`}
            classNameOverride={{
              loadingMessage: `${t('label:loading')}`,
              noOptionsMessage: `${t('label:noOptions')}`
            }}
            destructive={defaultFormControlProps.destructive}
          />
        </FormControlItem>
      ),
      currentSalary: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <SalaryControl
            value={value}
            onChange={onChange}
            defaultFormControlProps={defaultFormControlProps}
          />
        </FormControlItem>
      ),
      expectedSalary: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <SalaryControl
            value={value}
            onChange={onChange}
            defaultFormControlProps={defaultFormControlProps}
          />
        </FormControlItem>
      ),
      totalYearsOfExp: (
        <FormControlItem
          labelRequired={labelRequired}
          label={label}
          {...defaultFormControlProps}>
          <NativeSelect
            size="sm"
            isClearable
            isSearchable
            onChange={onChange}
            isDisabled={isDisabled}
            value={(totalYoeOptions || []).find(
              (item: ISelectOption) => String(item.value) === String(value)
            )}
            options={totalYoeOptions.map((item) => ({
              ...item,
              supportingObj: {
                name: `${t(`candidates:yoeOptions:${item.value}`)}`
              }
            }))}
            placeholder={`${t('careers:applied:selectExperienceLevel')}`}
            classNameOverride={{
              loadingMessage: `${t('label:loading')}`,
              noOptionsMessage: `${t('label:noOptions')}`
            }}
            destructive={defaultFormControlProps.destructive}
          />
        </FormControlItem>
      )
    }[systemFieldName] || systemFieldName
  )
}

export default SystemField
