import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import { Input } from '~/core/ui/Input'
import { InputRightElement } from '~/core/ui/InputElement'
import { InputGroup } from '~/core/ui/InputGroup'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { ISelectOption } from '~/core/ui/Select'

const SalaryControl: FC<{
  value?: any
  onChange: (value: any) => void
  defaultFormControlProps?: {
    destructive: boolean | undefined
    destructiveText: string | undefined
  }
}> = ({ value, onChange, defaultFormControlProps }) => {
  const { t, i18n } = useTranslation()
  const ProfileTypeOfCurrentSalaryEnums = useEnumsData({
    enumType: 'ProfileTypeOfCurrentSalary',
    locale: i18n.language
  })
  const currencySalary = useEnumsData({
    enumType: 'JobCurrency',
    locale: i18n.language
  })
  const defaultValueSalary = {
    typeOfSalary: ProfileTypeOfCurrentSalaryEnums?.[0],
    salary: 0,
    currency: currencySalary?.[0]
  }
  return (
    <div className="flex flex-row space-x-2">
      <div className="flex-1">
        <NativeSelect
          size="sm"
          isClearable={false}
          isSearchable={false}
          value={
            value?.typeOfSalary
              ? ProfileTypeOfCurrentSalaryEnums.filter(
                  (type: ISelectOption) =>
                    type.value === value?.typeOfSalary?.value
                )
              : ProfileTypeOfCurrentSalaryEnums[0]
          }
          onChange={(newValue) => {
            onChange({
              ...defaultValueSalary,
              ...value,
              typeOfSalary: newValue
            })
          }}
          options={ProfileTypeOfCurrentSalaryEnums}
          classNameOverride={{
            loadingMessage: `${t('label:loading')}`,
            noOptionsMessage: `${t('label:noOptions')}`
          }}
          menuPosition="fixed"
        />
      </div>
      <div className="flex-1">
        <InputGroup size="sm">
          <Input
            placeholder={'0.00'}
            value={value?.salary > 0 ? value?.salary : ''}
            onChange={(newValue) => {
              onChange({
                ...defaultValueSalary,
                ...value,
                salary: Number(newValue)
              })
            }}
            destructive={defaultFormControlProps?.destructive}
          />
          <InputRightElement>
            <NativeSelect
              size="sm"
              isClearable={false}
              isSearchable={false}
              onChange={(newValue) => {
                onChange({
                  ...defaultValueSalary,
                  ...value,
                  currency: newValue
                })
              }}
              value={!value?.currency ? currencySalary?.[0] : value?.currency}
              options={currencySalary}
              classNameOverride={{
                bordered: 'none',
                loadingMessage: `${t('label:loading')}`,
                noOptionsMessage: `${t('label:noOptions')}`
              }}
              menuPosition="fixed"
            />
          </InputRightElement>
        </InputGroup>
      </div>
    </div>
  )
}

export default SalaryControl
