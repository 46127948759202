import { useState } from 'react'
import { Control, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { ISelectOption } from '~/core/ui/Select'
import useCandidateProfile from '~/lib/features/candidates/hooks/use-query-candidate'
import { ILanguageParamType } from '~/lib/features/settings/profiles/edit/types'

const LanguageControlFormView = ({
  value,
  onChange,
  formState,
  control,
  triggerValidate
}: {
  value: ILanguageParamType[]
  onChange: (data: ILanguageParamType[]) => void
  formState?: Array<{
    language?: { message?: string }
    proficiency?: { message?: string }
  }>
  control: Control<any>
  triggerValidate: UseFormTrigger<any>
}) => {
  const { t } = useTranslation()
  const { languageList, languageProficiencies } = useCandidateProfile({})
  const [currentLanguage, setCurrentLanguage] = useState<any>({})
  const [errorText, setErrorText] = useState<string>('')

  // ---------- FUNCTION ---------- //

  const handleUpdate = () => {
    const newValue = [...(value || []), currentLanguage]
    onChange(newValue)
  }
  // ---------- RENDER ---------- //

  return (
    <FormControlItem destructive={!!errorText} destructiveText={errorText}>
      <div className="flex justify-between space-x-2">
        <div className="w-full">
          <NativeSelect
            isClearable={false}
            options={languageList}
            value={currentLanguage?.language}
            size="sm"
            onChange={(newValue) => {
              const selectedOption = (newValue as ISelectOption)?.__isNew__
                ? {
                    ...newValue,
                    supportingObj: {
                      name: (newValue as ISelectOption)?.label
                    }
                  }
                : newValue

              setCurrentLanguage({
                ...currentLanguage,
                language: selectedOption
              })
              setErrorText('')
            }}
            placeholder={`${t(
              'candidates:tabs:candidateOverview:languages:languagePlaceholder'
            )}`}
            showDropdownIndicator={false}
            creatable={false}
            classNameOverride={{
              loadingMessage: `${t('label:loading')}`,
              noOptionsMessage: `${t('label:noOptions')}`
            }}
          />
        </div>
        <div className="flex w-full flex-row items-center space-x-2">
          <div className="flex-1">
            <NativeSelect
              options={languageProficiencies}
              value={currentLanguage?.proficiency}
              size="sm"
              onChange={(newValue) => {
                setCurrentLanguage({
                  ...currentLanguage,
                  proficiency: newValue
                })
                setErrorText('')
              }}
              isClearable
              placeholder={`${t(
                'candidates:tabs:candidateOverview:languages:levelPlaceholder'
              )}`}
              classNameOverride={{
                loadingMessage: `${t('label:loading')}`,
                noOptionsMessage: `${t('label:noOptions')}`
              }}
            />
          </div>
          <Button
            htmlType="button"
            iconMenus="Plus"
            isDisabled={!currentLanguage.language}
            size="sm"
            type="tertiary"
            icon="leading"
            onClick={() => handleUpdate()}
          />
        </div>
      </div>
    </FormControlItem>
  )
}

export default LanguageControlFormView
