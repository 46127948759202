import { useRouter } from 'next/router'
import { FC } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { ZodType, ZodTypeDef } from 'zod'
import TermAndConditionsDetail from '~/components/Settings/Careers/TermAndConditionsDetail'
import { MAX_CHARACTERS_INTRODUCTION } from '~/core/constants/enum'
import { Button } from '~/core/ui/Button'
import { Checkbox } from '~/core/ui/Checkbox'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { UploadFileDragAndDrop } from '~/core/ui/FileDragAndDrop'
import { IFormAction } from '~/core/ui/Form'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import { PhoneInput } from '~/core/ui/PhoneInput'
import { Textarea } from '~/core/ui/TextArea'
import { useDetectCountryCodeFromTimeZone } from '~/lib/countries-mapping/hooks/use-detect-country-code-from-time-zone'
import { ApplicationFormType } from '~/lib/features/apply/jobId/types'
import { mappingDataSubmitApplicationFields } from '~/lib/features/candidates/mapping/application-fields-mapping'
import { ITermsAndConditions } from '~/lib/features/settings/careers/types'
import useUploadS3AWS from '~/lib/hooks/use-upload-s3-aws'
import useBoundStore from '~/lib/store'

const DefaultApplicationForm: FC<{
  isSubmitting: boolean
  defaultValue?: ApplicationFormType
  termAndConditions?: ITermsAndConditions
  schema?: ZodType<any, ZodTypeDef, any>
  onFinishCallback?: (
    data: ApplicationFormType,
    formAction: IFormAction
  ) => Promise<void>
  disableFields?: Array<string>
  onClickCancelModal?: () => void
}> = ({
  isSubmitting,
  defaultValue,
  termAndConditions,
  schema,
  onFinishCallback,
  disableFields = [],
  onClickCancelModal
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { countryCode } = useDetectCountryCodeFromTimeZone()
  const { user } = useBoundStore()
  const { files, setFiles } = useUploadS3AWS({
    defaultFiles: (defaultValue?.attachments || []).map((item) => ({
      id: item.id,
      url: '',
      name: item.blobs.filename,
      status: 'upload',
      statusDescription: '',
      file: undefined
    }))
  })
  const onSubmitData = (data: any, formAction: IFormAction) => {
    const jobBoards = router?.query?.job_boards as string
    const referredBy = router?.query?.referred_by as string

    const formattedData = mappingDataSubmitApplicationFields({
      ...data,
      ...(jobBoards ? { jobBoards } : {}),
      ...(referredBy ? { referredBy } : {})
    })
    return onFinishCallback
      ? onFinishCallback(formattedData as ApplicationFormType, formAction)
      : Promise.resolve()
  }
  return (
    <DynamicImportForm
      isShowDebug={false}
      id="application-form"
      className="w-full"
      defaultValue={{
        ...defaultValue,
        ...(termAndConditions?.enabling ? { termsAndConditions: false } : {})
      }}
      schema={schema}
      onSubmit={onSubmitData}>
      {({ formState, control }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const agreeTermAndConditions = useWatch({
          control,
          name: 'termsAndConditions'
        })
        return (
          <>
            <div className="mb-5 grid gap-y-5 tablet:mb-6 tablet:grid-cols-2 tablet:gap-x-6 tablet:gap-y-6">
              <div>
                <Controller
                  control={control}
                  name="fullName"
                  defaultValue={defaultValue?.fullName || ''}
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      labelRequired
                      label={`${t('careers:applied:fullName')}`}
                      destructive={
                        formState.errors && !!formState.errors?.fullName
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.fullName?.message as string)
                      }>
                      <Input
                        autoFocus
                        placeholder={`${t(
                          'careers:applied:fullNamePlaceholder'
                        )}`}
                        size="sm"
                        onChange={onChange}
                        value={value}
                        destructive={
                          formState.errors && !!formState.errors?.fullName
                        }
                        className="brand-color-input-focus"
                      />
                    </FormControlItem>
                  )}
                />
              </div>
              <div>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      labelRequired
                      label={`${t('label:placeholder:email')}`}
                      destructive={
                        formState.errors && !!formState.errors?.email
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.email?.message as string)
                      }>
                      <Input
                        placeholder={`${t('careers:applied:emailPlaceholder')}`}
                        size="sm"
                        onChange={onChange}
                        isDisabled={disableFields.includes('email')}
                        value={value}
                        destructive={
                          formState.errors && !!formState.errors?.email
                        }
                        className="brand-color-input-focus"
                      />
                    </FormControlItem>
                  )}
                />
              </div>
              <div>
                <Controller
                  control={control}
                  name="headline"
                  defaultValue={defaultValue?.headline || ''}
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      label={`${t('careers:applied:headline')}`}
                      destructive={
                        formState.errors && !!formState.errors?.headline
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.headline?.message as string)
                      }>
                      <Input
                        placeholder={`${t(
                          'careers:applied:headlinePlaceholder'
                        )}`}
                        size="sm"
                        onChange={onChange}
                        value={value}
                        destructive={
                          formState.errors && !!formState.errors?.headline
                        }
                        className="brand-color-input-focus"
                      />
                    </FormControlItem>
                  )}
                />
              </div>
              <div>
                <Controller
                  control={control}
                  name="phoneNumber"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      labelRequired
                      destructive={
                        formState.errors && !!formState.errors.phoneNumber
                      }
                      destructiveText={
                        formState.errors &&
                        formState.errors.phoneNumber?.message
                      }
                      label={`${t('form:phoneNumberProfileFieldLabel')}`}>
                      <PhoneInput
                        size="sm"
                        country={countryCode}
                        onChange={onChange}
                        value={value}
                        placeholder={`${t(
                          'form:phone_number_field_placeholder'
                        )}`}
                        destructive={
                          formState.errors && !!formState.errors.phoneNumber
                        }
                        inputClassName="brand-color-input-focus"
                      />
                    </FormControlItem>
                  )}
                />
              </div>
            </div>
            <div className="mb-5 tablet:mb-6">
              <Controller
                control={control}
                name="resumeFile"
                defaultValue={[]}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem
                      labelRequired
                      label={`${t('careers:applied:resumeCV')}`}
                      destructive={
                        formState.errors && !!formState.errors?.resumeFile
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.resumeFile?.message as string)
                      }>
                      <UploadFileDragAndDrop
                        className="w-full"
                        classNameWrapper="brand-color-input-focus"
                        textButtonClassName="brand-color-text"
                        configText={{
                          clickToUpload: `${t(
                            'label:dragAndDrop:clickToUpload'
                          )}`,
                          orDragAndDrop: `${t(
                            'label:dragAndDrop:orDragAndDrop'
                          )}`,
                          delete: `${t('tooltip:delete')}`,
                          tryAgain: `${t('tooltip:tryAgain')}`,
                          uploadANewFile: `${t('tooltip:uploadANewFile')}`
                        }}
                        dragNDropHelperText={`${t(
                          'careers:applied:dragNDropTypeFiles'
                        )}`}
                        maximumFiles={1}
                        accept={{
                          'application/pdf': ['.pdf'],
                          'application/msword': ['.doc'],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            ['.docx']
                        }}
                        files={files as File[]}
                        onChange={(files) => {
                          onChange(files)
                          setFiles(files || [])
                        }}
                        hideDeleteFileButton={true}
                      />
                    </FormControlItem>
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                control={control}
                name="coverLetter"
                defaultValue={defaultValue?.coverLetter || ''}
                render={({ field: { onChange, value } }) => (
                  <FormControlItem
                    label={`${t('careers:applied:coverLetter')}`}
                    destructive={
                      formState.errors && !!formState.errors?.coverLetter
                    }
                    destructiveText={
                      formState.errors &&
                      (formState.errors?.coverLetter?.message as string)
                    }>
                    <Textarea
                      size="sm"
                      onChange={onChange}
                      value={value}
                      limit={MAX_CHARACTERS_INTRODUCTION}
                      placeholder={`${t(
                        'careers:applied:coverLetterPlaceholder'
                      )}`}
                      destructive={
                        formState.errors && !!formState.errors?.coverLetter
                      }
                      wrapperFocusClassName="brand-color-ring brand-color-border"
                    />
                  </FormControlItem>
                )}
              />
            </div>
            {termAndConditions?.enabling && (
              <div>
                <TermAndConditionsDetail
                  description={termAndConditions?.description}>
                  {(openModal) => (
                    <Controller
                      control={control}
                      name="termsAndConditions"
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          size="sm"
                          className="items-start [&>button]:mt-0.5"
                          text={
                            <Trans
                              i18nKey="careers:term_and_conditions:description"
                              values={{
                                company_name: user?.currentTenant?.name
                              }}>
                              <a
                                className="brand-color-text font-medium text-primary-400 hover:cursor-pointer"
                                onClick={() => openModal && openModal(true)}
                              />
                            </Trans>
                          }
                          isChecked={value}
                          onCheckedChange={(e) => {
                            const checked = e.target.checked
                            onChange(checked)
                          }}
                          checkboxClassName="brand-color-button"
                        />
                      )}
                    />
                  )}
                </TermAndConditionsDetail>
              </div>
            )}
            <div className="-mx-4 mt-6 tablet:-mx-6">
              <div className="flex justify-end space-x-3 px-4 tablet:px-6">
                <Button
                  size="sm"
                  // isDisabled={isSubmitting || agreeTermAndConditions === false}
                  // isLoading={isSubmitting}
                  type="secondary"
                  label={`${t('button:cancel')}`}
                  onClick={onClickCancelModal}
                />
                <Button
                  size="sm"
                  isDisabled={isSubmitting || agreeTermAndConditions === false}
                  isLoading={isSubmitting}
                  htmlType="submit"
                  label={`${t('careers:applied:applyButton')}`}
                  className="brand-color-button"
                />
              </div>
            </div>
          </>
        )
      }}
    </DynamicImportForm>
  )
}

export default DefaultApplicationForm
