import { useRouter } from 'next/router'
import { FC } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import CustomField from '~/components/CustomField'
import SystemField, {
  SystemFieldType
} from '~/components/CustomField/SystemField'
import TermAndConditionsDetail from '~/components/Settings/Careers/TermAndConditionsDetail'
import { Button } from '~/core/ui/Button'
import { Checkbox } from '~/core/ui/Checkbox'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { IFormAction } from '~/core/ui/Form'
import schemaApplicationFormCustom from '~/lib/features/apply/jobId/schema/validation-application-form-custom'
import { ApplicationFormType } from '~/lib/features/apply/jobId/types'
import { mappingDataSubmitApplicationFields } from '~/lib/features/candidates/mapping/application-fields-mapping'
import { SystemFieldFormType } from '~/lib/features/candidates/types/system-fields'
import { ApplicationField } from '~/lib/features/jobs/types/application-fields'
import { ITermsAndConditions } from '~/lib/features/settings/careers/types'
import {
  formatInitialValueCustomField,
  mappingCustomFieldKind,
  renderKeyCustomFieldForm
} from '~/lib/features/settings/profile-fields/mapping/custom-field-mapping'
import { CustomFieldFormType } from '~/lib/features/settings/profile-fields/types/custom-field'
import useBoundStore from '~/lib/store'

const ApplicationCustomFieldsForm: FC<{
  isSubmitting?: boolean
  applicationFields: ApplicationField[]
  defaultValue?: ApplicationFormType
  onClickCancelModal?: () => void
  onSubmitForm?: (data: any, formAction: IFormAction) => Promise<void>
  termAndConditions?: ITermsAndConditions
  disableFields?: Array<string>
}> = ({
  isSubmitting,
  applicationFields,
  defaultValue,
  termAndConditions,
  onClickCancelModal,
  onSubmitForm,
  disableFields
}) => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const { user } = useBoundStore()
  const customFieldsFormatted = formatInitialValueCustomField(
    applicationFields.filter((field) => field.field_level == 'custom')
  )

  const onSubmit = (
    data: SystemFieldFormType & {
      jobId?: number
      customFields: CustomFieldFormType['customFields']
    },
    formAction: IFormAction
  ) => {
    const jobBoards = router?.query?.job_boards as string
    const referredBy = router?.query?.referred_by as string
    const resumeFiles = data?.profileCvs?.filter(
      (profileCV) => typeof profileCV !== 'string'
    )

    const formattedData = mappingDataSubmitApplicationFields({
      ...data,
      profileCvs: resumeFiles,
      ...(jobBoards ? { jobBoards } : {}),
      ...(referredBy ? { referredBy } : {})
    })

    return onSubmitForm
      ? onSubmitForm(formattedData, formAction)
      : Promise.resolve()
  }

  return (
    <DynamicImportForm
      isShowDebug={false}
      id="application-form"
      className="w-full"
      defaultValue={{
        ...defaultValue,
        customFields: customFieldsFormatted,
        profileCvs: defaultValue?.attachments?.map(
          (attachment) => attachment.file
        ),
        ...(termAndConditions?.enabling ? { termsAndConditions: false } : {})
      }}
      schema={schemaApplicationFormCustom({ t, applicationFields })}
      onSubmit={onSubmit}>
      {({ formState, control, trigger }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const agreeTermAndConditions = useWatch({
          control,
          name: 'termsAndConditions'
        })
        return (
          <>
            <Controller
              control={control}
              name="customFields"
              render={({
                field: { onChange: onChangeCustomFields, value: customFields }
              }) => {
                return (
                  <>
                    <div className="grid grid-cols-2 gap-6">
                      {applicationFields.slice(0, 4).map((field) => (
                        <div key={`system-field-${field.index}`}>
                          <Controller
                            control={control}
                            name={field.field_name as never}
                            render={({ field: { onChange, value } }) => (
                              <SystemField
                                name={field.field_name}
                                label={field.name?.[i18n.language]}
                                labelRequired={!!field.required}
                                error={formState.errors}
                                systemFieldName={
                                  field.field_name as SystemFieldType['systemFieldName']
                                }
                                extra={{
                                  attachments: defaultValue?.attachments
                                }}
                                value={value}
                                onChange={onChange}
                                control={control}
                                triggerValidate={trigger}
                                isDisabled={disableFields?.includes(
                                  field.field_name
                                )}
                              />
                            )}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-6">
                      {applicationFields.slice(4).map((field) => {
                        const key = renderKeyCustomFieldForm({
                          fieldKind: field.field_kind,
                          id: field.custom_field_id
                        })
                        return field.field_level === 'custom' ? (
                          <div key={`additional-field-${field.index}`}>
                            <CustomField
                              type={mappingCustomFieldKind(field.field_kind)}
                              display="default"
                              viewDefaultPlaceholder={`${t(
                                'candidates:tabs:candidateOverview:notAvailable'
                              )}`}
                              name={key}
                              error={formState.errors?.['customFields']}
                              label={field.name?.[i18n.language]}
                              labelRequired={!!field.required}
                              value={customFields?.[key]?.value}
                              onChange={(value) => {
                                onChangeCustomFields({
                                  ...customFields,
                                  [key]: {
                                    ...customFields?.[key],
                                    value
                                  }
                                })
                              }}
                              extraProps={{
                                options: field.select_options.map((option) => ({
                                  value: option.key,
                                  supportingObj: {
                                    name: option.description
                                  }
                                }))
                              }}
                            />
                          </div>
                        ) : (
                          <div key={`system-field-${field.index}`}>
                            <Controller
                              control={control}
                              name={field.field_name as never}
                              render={({ field: { onChange, value } }) => (
                                <SystemField
                                  name={field.field_name}
                                  label={field.name?.[i18n.language]}
                                  labelRequired={!!field.required}
                                  error={formState.errors}
                                  systemFieldName={
                                    field.field_name as SystemFieldType['systemFieldName']
                                  }
                                  extra={{
                                    attachments: defaultValue?.attachments
                                  }}
                                  value={value}
                                  onChange={onChange}
                                  control={control}
                                  triggerValidate={trigger}
                                  isDisabled={disableFields?.includes(
                                    field.field_name
                                  )}
                                />
                              )}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </>
                )
              }}
            />
            {termAndConditions?.enabling && (
              <div className="mt-6">
                <TermAndConditionsDetail
                  description={termAndConditions?.description}>
                  {(openModal) => (
                    <Controller
                      control={control}
                      name="termsAndConditions"
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          size="sm"
                          className="items-start [&>button]:mt-0.5"
                          text={
                            <Trans
                              i18nKey="careers:term_and_conditions:description"
                              values={{
                                company_name: user?.currentTenant?.name
                              }}>
                              <a
                                className="brand-color-text font-medium text-primary-400 hover:cursor-pointer"
                                onClick={() => openModal && openModal(true)}
                              />
                            </Trans>
                          }
                          isChecked={value}
                          onCheckedChange={(e) => {
                            const checked = e.target.checked
                            onChange(checked)
                          }}
                          checkboxClassName="brand-color-button"
                        />
                      )}
                    />
                  )}
                </TermAndConditionsDetail>
              </div>
            )}

            <div className="-mx-4 mt-6 tablet:-mx-6">
              <div className="flex justify-end space-x-3 px-4 tablet:px-6">
                <Button
                  size="sm"
                  // isDisabled={isSubmitting || agreeTermAndConditions === false}
                  // isLoading={isSubmitting}
                  type="secondary"
                  label={`${t('button:cancel')}`}
                  onClick={onClickCancelModal}
                />
                <Button
                  size="sm"
                  isDisabled={isSubmitting || agreeTermAndConditions === false}
                  // isLoading={isSubmitting}
                  htmlType="submit"
                  label={`${t('careers:applied:applyButton')}`}
                  className="brand-color-button"
                />
              </div>
            </div>
          </>
        )
      }}
    </DynamicImportForm>
  )
}

export default ApplicationCustomFieldsForm
