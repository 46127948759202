import { Control, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconButton } from '~/core/ui/IconButton'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import {
  ILanguageParamType,
  ISectionCustomFieldParamType
} from '~/lib/features/settings/profiles/edit/types'
import LanguageControlFormView from './LanguageControlFormView'

const LanguageControl = ({
  section,
  value,
  onChange,
  formState,
  control,
  triggerValidate
}: {
  section: ISectionCustomFieldParamType
  value: ILanguageParamType[]
  onChange: (data: ILanguageParamType[]) => void
  formState?: Array<{
    language?: { message?: string }
    proficiency?: { message?: string }
  }>
  control: Control<any>
  triggerValidate: UseFormTrigger<any>
}) => {
  const { t } = useTranslation()

  // ---------- FUNCTION ---------- //
  const handleDelete = ({ itemIndex }: { itemIndex: number }) => {
    const newValue = (value || []).filter(
      (_, index) => String(index) !== String(itemIndex)
    )
    onChange(newValue)

    return
  }

  // ---------- RENDER ---------- //

  return (
    <div>
      <div className="mt-2 space-y-2 pb-2">
        <LanguageControlFormView
          value={value}
          formState={formState}
          control={control}
          triggerValidate={triggerValidate}
          onChange={onChange}
        />
      </div>
      <div className="space-y-2">
        {(value || []).map((item, index) => (
          <div
            key={index}
            className="group relative flex items-center space-x-2">
            <div className="text-sm font-medium text-gray-900">
              {item?.language?.supportingObj?.name}
            </div>
            {item?.proficiency?.supportingObj?.name && (
              <>
                <div className="mx-2 h-0.5 w-0.5 bg-gray-400" />
                <div className="text-sm text-gray-900">
                  {item?.proficiency?.supportingObj?.name}
                </div>
              </>
            )}
            <div className="absolute right-0 top-0 opacity-0 group-hover:opacity-100">
              <Tooltip content={t('tooltip:delete')}>
                <IconButton
                  iconMenus="Trash2"
                  size="xs"
                  type="secondary-destructive"
                  onClick={() => handleDelete({ itemIndex: index })}
                />
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LanguageControl
