import { formatSubmitCustomFieldData } from '../../settings/profile-fields/mapping/custom-field-mapping'
import { CustomFieldFormType } from '../../settings/profile-fields/types/custom-field'
import { SystemFieldFormType } from '../types/system-fields'

const checkNullAndInsert = ({
  condition,
  valueInsert
}: {
  condition: boolean
  valueInsert: Object
}) => (condition ? valueInsert : {})

export const mappingDataSubmitApplicationFields = (
  data: SystemFieldFormType & {
    jobId?: number
    resumeFile?: Array<File> //this field use in default application form
    customFields: CustomFieldFormType['customFields']
  }
) => {
  return {
    ...checkNullAndInsert({
      condition: !!data.jobId,
      valueInsert: {
        jobId: data.jobId
      }
    }),
    ...checkNullAndInsert({
      condition: data.fullName.length > 0,
      valueInsert: {
        fullName: data.fullName
      }
    }),
    ...checkNullAndInsert({
      condition: data.phoneNumber.length > 0,
      valueInsert: {
        phoneNumber: data.phoneNumber
      }
    }),
    ...checkNullAndInsert({
      condition: data.email.length > 0,
      valueInsert: {
        email: data.email
      }
    }),
    ...checkNullAndInsert({
      condition: (data.profileCvs || data?.resumeFile || [])?.length > 0,
      valueInsert: {
        resumeFile: data.profileCvs?.[0] || data?.resumeFile?.[0]
      }
    }),
    ...checkNullAndInsert({
      condition: (data.headline || '')?.length > 0,
      valueInsert: {
        headline: data.headline
      }
    }),
    ...checkNullAndInsert({
      condition: (data.coverLetter || '')?.length > 0,
      valueInsert: {
        coverLetter: data.coverLetter
      }
    }),
    ...checkNullAndInsert({
      condition: (data?.coverLetter || '')?.length > 0,
      valueInsert: {
        coverLetter: data?.coverLetter
      }
    }),
    ...checkNullAndInsert({
      condition: (data?.jobBoards || '')?.length > 0,
      valueInsert: {
        jobBoards: data?.jobBoards
      }
    }),
    ...checkNullAndInsert({
      condition: (data?.referredBy || '')?.length > 0,
      valueInsert: {
        referredBy: data?.referredBy
      }
    }),
    ...checkNullAndInsert({
      condition: !!data?.termsAndConditions,
      valueInsert: {
        termsAndConditions: data?.termsAndConditions
      }
    }),
    ...checkNullAndInsert({
      condition: !!data?.profileLevel?.value,
      valueInsert: {
        profileLevel: data?.profileLevel?.value
      }
    }),
    ...checkNullAndInsert({
      condition: !!data?.birthday?.date,
      valueInsert: {
        birthday: {
          birth_year: String(data?.birthday?.year),
          birth_month: String(data?.birthday?.month),
          birth_date: String(data?.birthday?.date)
        }
      }
    }),
    ...checkNullAndInsert({
      condition: (data?.languages || [])?.length > 0,
      valueInsert: {
        languages: data?.languages?.map((languageItem, index) => ({
          index: index + 1,
          language: languageItem?.language?.value,
          languageDescription: languageItem?.language?.supportingObj?.name,
          proficiency: languageItem?.proficiency?.value,
          proficiencyDescription: languageItem?.proficiency?.supportingObj?.name
        }))
      }
    }),
    ...checkNullAndInsert({
      condition: (data?.nationality || '')?.length > 0,
      valueInsert: {
        nationality: data?.nationality
      }
    }),
    ...checkNullAndInsert({
      condition: typeof data.willingToRelocate !== 'undefined',
      valueInsert: {
        willingToRelocate: data?.willingToRelocate
      }
    }),
    ...checkNullAndInsert({
      condition: !!data?.noticeToPeriodDays?.value,
      valueInsert: {
        noticeToPeriodDays: data?.noticeToPeriodDays?.value
      }
    }),
    ...checkNullAndInsert({
      condition: (data?.currentSalary?.salary || 0) > 0,
      valueInsert: {
        currentSalary: data?.currentSalary?.salary,
        typeOfCurrentSalary: data?.currentSalary?.typeOfSalary?.value,
        currentSalaryCurrency: data?.currentSalary?.currency?.value
      }
    }),
    ...checkNullAndInsert({
      condition: (data?.expectedSalary?.salary || 0) > 0,
      valueInsert: {
        expectedSalary: data?.expectedSalary?.salary,
        typeOfExpectedSalary: data?.expectedSalary?.typeOfSalary?.value,
        expectedSalaryCurrency: data?.expectedSalary?.currency?.value
      }
    }),
    ...checkNullAndInsert({
      condition: !!data?.totalYearsOfExp?.value,
      valueInsert: {
        totalYearsOfExp: Number(data?.totalYearsOfExp?.value)
      }
    }),
    ...checkNullAndInsert({
      condition: Object.keys(data?.customFields || {}).length > 0,
      valueInsert: {
        customFields: formatSubmitCustomFieldData(data.customFields)
      }
    })
  }
}
